import React, { Component } from 'react'
import Link from 'gatsby-link'
import Layout from '../../components/layout'
import { formatDate } from '../../helpers'
import classNames from './TechRadar.module.css'

class TechRadar extends Component {
  trim = (str, delimiter) => {
    const pattern = `[^\\${delimiter}]`
    const start = str.search(pattern)
    const stop =
      str.length -
      str
        .split('')
        .reverse()
        .join('')
        .search(pattern)
    return str.substring(start, stop)
  }
  async componentDidMount() {
    try {
      const slug = this.props.pathContext.slug
      const DATA_FILENAME = slug ? this.trim(slug, '/') + '.json' : `data.json`
      const { default: radarData } = await import(`./data/${DATA_FILENAME}`)
      const { default: drawTechRadar } = await import('./radar')
      drawTechRadar(radarData)
    } catch (err) {
      console.log('err =', err)
    }
  }

  render() {
    const { pageContext: { category, date } } = this.props
    return (
      <Layout>
        <div className={classNames.mainContainer}>
          <div className={classNames.pageHeaderContainer}>
            <h2>Technology radar</h2>
            <div className={classNames.headerOptionsContainer}>
              <div className={classNames.backLink}>
                <Link to="/">&lt; BACK TO LIST</Link>
              </div>

              <div>
                <span className={classNames.categoriesContainer}>
                  <Link to={`/categories/${encodeURIComponent(category)}`}>{category}</Link>
                </span>
                <span className={classNames.dateContainer}> {formatDate(date)}</span>
              </div>
            </div>
          </div>
          <svg id="techRadar" viewBox="0 0 1010 1200" preserveAspectRatio="xMidYMid meet" />
          <div id="legendListContainer" className={classNames.legendListContainer} />
          <div className={classNames.descriptionContainer}>
            <p>
              This is the AUTO1 version of the <a href="https://www.thoughtworks.com/radar">ThoughtWorks Tech Radar</a>.
            </p>
            <p>
              These are most of the technologies, techniques and tools we’re using. We clustered them in rings and
              quadrants, just as the folks at ThoughtWorks do.
            </p>
            <h3>Rings</h3>
            <p>
              <b>ADOPT - </b>We widely use this and recommend everyone to do the same.
            </p>
            <p>
              <b>TRIAL - </b>We have some experience with this and are actively trying this out. We have been successful
              so far with these trials.
            </p>
            <p>
              <b>ASSESS - </b>This is new and we would like to try it out soon, but haven’t yet found the right way to
              trial it. Every member of our TECH department is allowed and asked to propose new blips for this stage.
            </p>
            <p>
              <b>HOLD - </b>We have been using this in the past and might still be using it widely, but new projects
              should not consider it and existing projects should move away eventually.
            </p>
            <h3>Quadrants</h3>
            <p>
              We adhere to the quadrant (octant in our case) definition of ThoughtWorks, copied for your convenience
              from this <a href="https://www.thoughtworks.com/radar/faq">link</a>:
            </p>
            <p>
              <b>Programming Languages and Frameworks - </b>This was just languages but we rolled frameworks into here
              with the October 2012 Radar.
            </p>
            <p>
              <b>Tools - </b>These can be components, such as databases, software development tools, such as versions
              control systems; or more generic categories of tools, such as the notion of polyglot persistence.
            </p>
            <p>
              <b>Platforms - </b>Things that we build software on top of such as mobile technologies like Android,
              virtual platforms like the JVM, or generic kinds of platforms like hybrid clouds.
            </p>
            <p>
              <b>Techniques - </b>These include elements of a software development process, such as experience design;
              and ways of structuring software, such as microservices.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TechRadar
